@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	position: fixed;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	position: relative;
}

body > div {
	height: 100%;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

*:focus {
	outline: none;
}

@font-face {
	font-family: "BauerBodoni";
	src: url("./fonts/BauerBodoniRegular.otf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Gotham";
	src: url("./fonts/Gotham-Book.otf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Gotham";
	src: url("./fonts/Gotham-Bold.otf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Gotham";
	src: url("./fonts/Gotham-Bold.otf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

img.responsive {
	width: 100%; /* This sets the width of the image to the width of its container */
	height: auto; /* This allows the height to adjust proportionally to the width */
}

.background-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
}

.background-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url("./EventAssets/Web asset 3 - Background 4.webp");
	background-size: cover;
	background-position: center;
	filter: blur(8px);
	transform: scale(1.1);
	opacity: 0.6;
}
